@import "src/styles/_variables-mixins.scss";
.btnTrigger {
  all: unset;
  padding: 0;
  margin: 0;

  border: 0;
  background-color: transparent;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  position: relative;
  z-index: 30;
  min-width: 220px;
  background-color: var(--mobility-color-dark-50);
  border-radius: 6px;
  padding: 5px;
  box-shadow: 1px 8px 20px 0px rgba(0, 0, 0, 0.5);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.DropdownMenuArrow {
  fill: var(--mobility-color-dark-50);
}

.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuSubTrigger {
  display: flex;
  align-items: center;

  height: 25px;
  padding: 5px 15px;
  position: relative;
  margin: 2px 0;

  font-size: 13px;
  line-height: 1;
  color: var(--mobility-color-orange-600);

  border-radius: 3px;
  user-select: none;
  outline: none;
  cursor: pointer;
}
.DropdownMenuSubTrigger[data-state='open'] {
  background-color: var(--mobility-color-orange-50);
  color: var(--mobility-color-orange-600);
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mobility-color-dark-300);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: var(--mobility-color-orange-50);
  color: var(--mobility-color-orange-600);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mobility-color-light-700);
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--mobility-color-light-300);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
